// import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Status from 'Component/Status';
import './HyperpayStatus.style';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';
import HyperpayStatusQuery from '../../query/HyperpayStatus.query';
import Loader from 'Component/Loader';
import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request/Request';

/** @namespace AlmusbahblendPwa/Route/HyperpayStatus/Component */
export class HyperpayStatusComponent extends PureComponent {
 
    __construct(props) {
        super.__construct?.(props);

        this.state = {
            isLoading: true,
            orderID: 'NA',
            success: false, 
            result: true,
            email: '',
            firstname: '',
            lastname: '',
        };
    }

    componentDidMount() {
        // console.log("Window--> ", window);
        console.log("Window.location --> ", window.location);
        // console.log("Window.href --> ", window.href);

        const urlParams = new URLSearchParams(window.location.search);
        console.log("urlParams --> ", urlParams);
        const paymentMethod = urlParams.get('method');
        const paymentId = urlParams.get('id');
        const paymentResourcePath = urlParams.get('resourcePath');
    
        // const values3 = useRouteMatch();
        // console.log("useRouteMatch values: ", values3);

        
        // console.log(" In Details this.props is: ", this.props);
        // const {
        //     match: { params = {} },
        // } = this.props;
        // console.log(" In Details params is: ", params);
        // const { method } = params;
        // console.log(" In Details method is: ", method);


        // const arr = method.split(/[?&]/g);
        // console.log(" String arr is: ", arr);


        // let paymentMethod = 'HyperPay_Cards';
        // let paymentId = 'OUHUIIU087870KJNKLKNLKN.IUJHIU988weiu.fh080';
        // let paymentResourcePath = '/V1/path/OUHUIIU087870KJNKLKNLKN.IUJHIU988weiu.fh080';

        // arr.forEach(pair => {
        //     let keyValue = pair.split("=");
        //     if ( keyValue[0] == 'method') {
        //         paymentMethod = keyValue[1];
        //     } else if ( keyValue[0] == 'id') {
        //         paymentId = keyValue[1];
        //     } else if ( keyValue[0] == 'resoursePath') {
        //         paymentResourcePath = keyValue[1];
        //     }
        // });
        const paymentData = {
            method: paymentMethod,
            checkoutId: paymentId,
            resoursePath: paymentResourcePath
        };

        const query = HyperpayStatusQuery.getHyperpayStatus(paymentId, paymentMethod);
        executePost(prepareQuery([query])).then(
            /** @namespace Component/Status/Container/executePost/then */
            ( formUrl ) => {
                let successs = false;
                console.log("=== requestPaymentStatus result: ", formUrl);
                let paymentStatus = formUrl.getpaymentstatus.paymentStatus;
                successs = formUrl.getpaymentstatus.success;
                let order_id = (formUrl.getpaymentstatus.orderId) ? formUrl.getpaymentstatus.orderId : 'NA';
                this.setState({ 
                    success: successs, 
                    orderID: order_id, 
                    result: true,
                    email: formUrl.getpaymentstatus.email,
                    firstname: formUrl.getpaymentstatus.firstname,
                    lastname: formUrl.getpaymentstatus.lastname,
                    isLoading: false,
                });
                // this.renderNextPage(success);
                // this.setState({ isLoading: false, checkoutId: formUrl.getpaymenturl.formUrl });
                // console.log("=== check step 5: ");
            }
        ).catch(
            /** @namespace Component/Status/Container/executePost/then/catch */
            () => {
                this.setState({ isLoading: false });
                // this.renderNextPage(false);
            }
        );
    }

    redirectToStatus() {
        const {
            orderID,
            success,
        } = this.state;
        if(success) {
            history.push(appendWithStoreCode(`/order/success/${orderID}/success`));
        } else {
            history.push(appendWithStoreCode(`/order/failure/${orderID}/failed`));
        }
        
    }

    renderFullPageLoader() {
        return (
             <main block="Checkout" elem="FullPageLoader">
                 <Loader isLoading />
             </main>
        );
    }

    renderContent() {
        const {
            isLoading
        } = this.state;

        if (isLoading) {
            return this.renderFullPageLoader();
        }

        return this.redirectToStatus();
    }

    render() {
        return (
            <div block="HyperpayStatus">
                { this.renderContent() }
            </div>
            
        )
    }
}

export default HyperpayStatusComponent;
