// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Loader from 'Component/Loader';
import { appendWithStoreCode } from 'Util/Url';
import './ProceedPayment.style';

/** @namespace AlmusbahblendPwa/Component/ProceedPayment/Component */
export class ProceedPaymentComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        isOrderButtonVisible: false,
        isOrderButtonEnabled: false,
        isTACAccepted: false,
    };

    static defaultProps = {
        isLoading: false,
        cartTotalSubPrice: null,
        paymentMethod: '',
    };

    displayName(element) {
        $('.wpwl-brand-card').each(function () {
            $(element).append(this);
        });
    }

    renderHyperpayForm() {
        const {
            checkoutId,
            selectedPaymentMethod,
            baseUrl,
        } = this.props;

        let storeCode = appendWithStoreCode('');
        storeCode = (storeCode.startsWith('/') ? storeCode.slice(1) : storeCode);
        console.log("Storecode in HyperPay: ", storeCode);

        let storeLang = storeCode == 'en_US' ? 'en' : 'ar';

        console.log("this.props.checkoutId--> ", checkoutId);
        let options = {}
        let brands = "VISA MASTER";
        
        if (selectedPaymentMethod == "HyperPay_Mada") {
            brands = "MADA";
        }

        if(selectedPaymentMethod == "HyperPay_ApplePay"){
            brands = "APPLEPAY";
            options = {
                applePay: {
                    merchantCapabilities:["supports3DS"],
                    supportedNetworks: ["amex", "masterCard", "visa", "mada"]
                },
                paymentTarget:"_top",
            }
        } else {
            options = {
                paymentTarget:"_top",
                maskCvv: true,
                brandDetection: true,
                locale: storeLang,
                showCVVHint: true,
                // iframeStyles: {
                //     'cvv-placeholder': { 'color': '#000000', 'font-size': '13px', 'font-family': 'Arial' },
                //     'card-number-placeholder': { 'color': '#000000', 'font-size': '13px', 'font-family': 'Arial' }
                // },
                onReady: function () {
                    $('.wpwl-wrapper-cardNumber').each(function () {
                        this.displayName(this);
                    });
                    $('[name="form_key"]').remove();
        
                }
            };
        }
        
        const wpwlOptions = JSON.stringify(options);
        console.log('wpwlOptions: ', wpwlOptions);
        const optSctipt = document.createElement('script');
        optSctipt.innerHTML = `var wpwlOptions=${wpwlOptions}`;
        
        const script = document.createElement('script');
        // script.src = `https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=835530225EB82223E1D03327787154C2.uat01-vm-tx02`;
        script.src = checkoutId;
        script.async = true;

        document.body.appendChild(optSctipt);
        document.body.appendChild(script);

        return (
            <form 
            action={ baseUrl + "/hyperpay/status?method="+ selectedPaymentMethod }
            class="paymentWidgets" 
            data-brands= { brands }
            ></form>
        );
    }

    renderContent() {

        const {
            isLoading
        } = this.props;
        
        if (isLoading) {
            return <Loader isLoading />;
        }

        return this.renderHyperpayForm();
    }

    render() {
        return (
            <div block="ProceedPayment">
                { this.renderContent() }
            </div>
        );
    }
}

export default ProceedPaymentComponent;
