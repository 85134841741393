import {
    MyAccountQuery as SourceMyAccountQuery,
} from 'SourceQuery/MyAccount.query';

import { Field, Mutation, Query } from '@tilework/opus';

/** @namespace AlmusbahblendPwa/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    // TODO implement logic

    /**
     * Get ForgotPassword mutation
     * @param {{email: String}} options
     * @returns {Field}
     * @memberof MyAccount
     */
    getForgotPasswordMutation(
        options,
    ) {
        const { email } = options;

        return new Mutation('requestPasswordResetEmail')
            .addArgument('email', 'String!', email);

        // return new Mutation('forgotPassword')
        //     .addArgument('email', 'String!', email)
        //     .addField('status');
    }
}

export default new MyAccountQuery();
