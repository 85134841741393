import {
    CheckoutPaymentComponent as SourceCheckoutPaymentComponent,
} from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';

import { HYPERPAY_APPLEPAY, HYPERPAY_CARDS, HYPERPAY_MADA, TAMARA_PAYMENT } from 'Route/OnepageCheckout/OnepageCheckout.component';

import Field from 'Component/Field';
import { FieldType } from 'Component/Field/Field.config';
import { getStoreState } from 'Util/Store';

import './CheckoutPayment.override.style';

/** @namespace AlmusbahblendPwa/Component/CheckoutPayment/Component */
export class CheckoutPaymentComponent extends SourceCheckoutPaymentComponent {
    // TODO implement logic

    showImage() {
        const {
            onClick,
            method,
        } = this.props;
        const { ConfigReducer: { base_link_url = window.location.href, base_url = window.location.href } = {} } = getStoreState();
        if (method.code == HYPERPAY_CARDS) {
            let imageurl = base_url + 'static/frontend/scandipwa/almusbahblend-pwa/en_US/Hyperpay_Extension/images/cards.jpg';
            return(
                <>
                <img src={imageurl}  class="Cards_img"></img>
                </>
            );
        } else if (method.code == HYPERPAY_APPLEPAY) {
            let imageurl = base_url + 'static/frontend/scandipwa/almusbahblend-pwa/en_US/Hyperpay_Extension/images/apple.svg';
            return(
                <>
                <img src={imageurl} class="Apple_img"></img>
                </>
            );
        } else if (method.code == HYPERPAY_MADA) {
            let imageurl = base_url + 'static/frontend/scandipwa/almusbahblend-pwa/en_US/Hyperpay_Extension/images/mada.svg';
            return(
                <>
                <img src={imageurl}  class="Mada_img"></img>
                </>
            );
        } else if (method.code.includes(TAMARA_PAYMENT)) {
            let imageurl = 'https://cdn.tamara.co/assets/svg/tamara-logo-badge-en.svg';
            return(
                <>
                <img src={imageurl}  class="Tamara_img"></img>
                </>
            );
        } else {
            return null;
        }
        
    }

    render() {
        const {
            isSelected,
            method: { title },
        } = this.props;

        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li block="CheckoutPayment">
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ this.onClick }
                >
                    <Field
                      type={ FieldType.CHECKBOX }
                      attr={ {
                          id: `option-${ title }`,
                          name: `option-${ title }`,
                          checked: isSelected,
                      } }
                      label={ title }
                      isDisabled={ false }
                    />
                    { this.showImage() }
                </button>
            </li>
        );
    }
}

export default CheckoutPaymentComponent;
