import {
    OrderQuery as SourceOrderQuery
} from 'SourceQuery/Order.query';

import { Field, Mutation, Query } from '@tilework/opus';

/** @namespace AlmusbahDevPwa/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    // TODO implement logic
    _getOrdersField(options) {
        const { orderId = 0, orderNumber = '', page = 1, sort= {} } = options || {};
        const ordersField = new Field('orders', 'CustomerOrders');

        if (orderId) {
            return ordersField
                .addArgument('filter', 'CustomerOrdersFilterInput', { entity_id: { eq: orderId } })
                .addArgument('scope', 'ScopeTypeEnum', 'WEBSITE')
                .addFieldList(this._getOrdersFields(true));
        }

        if (orderNumber != '') {
            return ordersField
                .addArgument('filter', 'CustomerOrdersFilterInput', { number: { eq: orderNumber } })
                .addArgument('scope', 'ScopeTypeEnum', 'WEBSITE')
                .addFieldList(this._getOrdersFields(true));
        }

        this._addSortOrderList(sort, ordersField);

        return ordersField
            .addArgument('currentPage', 'Int', page)
            .addArgument('scope', 'ScopeTypeEnum', 'WEBSITE')
            .addFieldList(this._getOrdersFields());
    }

    getOrderData(order_id = '', order_number = '') {
        const orderField = new Field('getorder');
        if (order_id) {
            return orderField
                .addArgument('order_id', 'String', order_id)
                .addFieldList(this._getOrderDataFields(true));
        } else if (order_number) {
            return orderField
            .addArgument('order_number', 'String', order_number)
            .addFieldList(this._getOrderDataFields(true));
        }

    }

    _getOrderDataFields() {
        return [
            new Field('id'),
            new Field('increment_id'),
            new Field('grand_total'),
            new Field('coupon_code'),
            new Field('applied_rule_ids'),
            new Field('discount'),
            new Field('cod_fee_incl_tax'),
            new Field('shipping_incl_tax'),
            new Field('tax_amount'),
            new Field('subtotal_including_tax'),
            new Field('email'),
            new Field('total_item_count'),
            new Field('amount_paid'),
            new Field('shipping_method'),
            new Field('payment_method'),
            new Field('transaction_id'),
            this._getOrderItemsProductFields(),
            this._getOrderShippingAddressFields()
        ]
    }

    _getOrderItemsProductFields() {
        return new Field('items', true)
            .addFieldList(this._getOrderItemProductFields());
    }

    _getOrderItemProductFields() {
        return [
            new Field('id'),
            new Field('salable_qty'),
            new Field('sku'),
            new Field('name'),
            new Field('price'),
            new Field('thumbnail'),
            new Field('small_image'),
            new Field('image'),
            new Field('stock_status'),
            new Field('regular_price'),
            new Field('total_item_discount'),
            new Field('final_price'),
            new Field('type_id'),
        ];
    }

    _getOrderShippingAddressFields() {
        return new Field('address')
            .addFieldList(this._getOrderAddressFieldss());
    }

    _getOrderAddressFieldss() {
        return [
            new Field('city'),
            new Field('country'),
            new Field('firstname'),
            new Field('lastname'),
            new Field('email'),
            new Field('postcode'),
            new Field('state'),
            new Field('telephone'),
            this._getOrderAddressStreetField(),
        ];
    }

    _getOrderAddressStreetField() {
        return new Field('street', true);
    }

    _getOrderPaymentMethodsField() {
        return new Field('payment_methods', true)
            .addFieldList(this._getOrderPaymentMethodsFields());
    }

    _getOrderPaymentMethodsFields() {
        return [
            new Field('name'),
            new Field('type'),
            new Field('purchase_number'),
            this._getOrderPaymentMethodAdditionalField(),
        ];
    }

    _getOrderPaymentMethodAdditionalField() {
        return new Field('additional_data')
            .addFieldList(this._getOrderPaymentMethodAdditionalFields());
    }

    _getOrderPaymentMethodAdditionalFields() {
        return [
            new Field('name'),
            new Field('value'),
        ];
    }

    getOrderListQuery(options) {
        console.log("getOrderListQuery options:", options);
        return new Query('customer')
            .addFieldList(this._getOrderListFields(options));
    }

    // _getOrdersField(options) {
    //     const { orderId = 0, page = 1, sort = {} } = options || {};
    //     const query = new Field('orders');

    //     if (orderId) {
    //         return query
    //             .addArgument('filter', 'CustomerOrdersFilterInput', { entity_id: { eq: orderId } })
    //             .addFieldList(this._getOrdersFields(true));
    //     }

    //     this._addSortOrderList(sort, query);

    //     return query
    //         .addArgument('currentPage', 'Int', page)
    //         .addFieldList(this._getOrdersFields());
    // }

    _addSortOrderList(sort, query) {
        if (Object.keys(sort).length != 0) {
            query.addArgument('sort', 'CustomerOrderSortInput', sort);
        }
    }

    _getOrderItemTotalFields() {
        return [
            this._getOrderGrandTotalField(),
            this._getOrderDiscountsField(),
            this._getOrderBaseGrantTotalField(),
            this._getOrderSubtotalField(),
            this._getOrderCodFeeField(),
            this._getOrderTotalShippingField(),
            this._getOrderTotalTaxField(),
            this._getOrderShippingHandlingField(),
            this._getOrderTaxesField(),
        ];
    }

    _getOrderCodFeeField() {
        return new Field('cod_fee_incl_tax')
            .addFieldList(this._getOrderPriceFields());
    }
}

export default new OrderQuery();
