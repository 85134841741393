/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { appendWithStoreCode } from 'Util/Url';

export const CheckoutSteps = {
    CHECKOUT_STEP: 'CHECKOUT_STEP',
    PAYMENT_STEP : 'PAYMENT_STEP',
    DETAILS_STEP : 'DETAILS_STEP',
}

export const CheckoutUrlSteps = {
    CHECKOUT_URL_STEP : 'onepagecheckout',
    PAYMENT_URL_STEP : 'payment',
    DETAILS_URL_STEP : 'success',
}

export const CheckoutStepUrl = {
    CHECKOUT_URL: '/onepagecheckout',
    CHECKOUT_PAYMENT_URL: '/onepagecheckout/payment',
    CHECKOUT_SUCCESS_URL : '/onepagecheckout/success',
}

export const CHECKOUT_URL_REGEX = new RegExp(`^(${appendWithStoreCode('')})?${CheckoutStepUrl.CHECKOUT_URL}(/)?$`);

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';

export const UPDATE_EMAIL_CHECK_FREQUENCY = 1500; // ms
export const UPDATE_SHIPPING_COST_ESTIMATES_FREQUENCY = 800; // ms
